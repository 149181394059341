<template>
    <div class="about_page">
        <custom-head></custom-head>
        <div class="banner">
            <img class="w_h_100" :src="pcCosBaseUrl+'about_banner_img.jpg'" alt="">
        </div>
        <div class="intro_wrap">
            <h1>GETO 解套</h1>
            <div class="intro_img_wrap flex_align_start">
                <img class="intro_img" :src="pcCosBaseUrl+'about_intro_img.png'" alt="">
                <div class="intro_content_wrap">
                    <p>解套GETO致力于打造好玩有趣、年轻时尚的两性情趣生活品牌，我们在甜酷女孩的生活视角中汲取灵感，倡导用积极乐观、激情洒脱的生活态度去面对现实中的困惑，相信一切都能迎刃而解。一个更懂女生的情趣品牌，也要让男生更懂女生的情趣品牌。
                    </p>
                    <img class="about_tag" src="@/assets/pc/img/about_tag.png" alt="">
                    <img class="tag_2" src="@/assets/pc/img/tag_2.png" alt="">
                </div>
            </div>
        </div>
        <div class="big_img" :style="{backgroundImage: 'url('+pcCosBaseUrl+'about_big_img_1.png)'}"></div>
        <div class="pack_wrap">
            <h2>这一次</br>重新定义两性生活</h2>
            <div class="pack_img_wrap flex_align_start">
                <img class="pack_img" :src="pcCosBaseUrl+'about_pack_img.png'" alt="">
                <div class="pack_content">
                    <h3>MINI信封包装</h3>
                    <p>通过极具创意特色的“外衣”，改变人们对于安全套</br>的刻板印象。</br>这一次</br>我们选择mini时尚的信封包装，</br>让套套不必蒙上羞耻面纱</p>
                    <p>邀请多名设计师联合创作‘姿识’卡片</br>使用户收到的每一张盲卡都变成一份惊喜</p>
                </div>
                <img class="pack_img_mini_1" :src="pcCosBaseUrl+'about_pack_img_mini_1.png'" alt="">
                <img class="pack_img_mini_2" :src="pcCosBaseUrl+'about_pack_img_mini_2.png'" alt="">
                <img class="pack_img_mini_3" :src="pcCosBaseUrl+'about_pack_img_mini_3.png'" alt="">
                <img class="text_tag" src="@/assets/pc/img/about_pack_text_tag.png" alt="">
            </div>
        </div>
        <div class="originality_wrap">
            <div class="originality_content flex_col flex_align_start"
                :style="{backgroundImage: 'url('+pcCosBaseUrl+'about_originality_img.png)'}">
                <div class="title_wrap title_spot flex_align_center flex_justify_between">
                    <h1>好玩有趣</h1>
                    <h1>创意周边</h1>
                </div>
                <img class="originality_title" src="@/assets/pc/img/originality_title.png" alt="">
                <p class="originality_text">
                    从都市青年的视角出发打破市场上</br>对于两性生活用品的陈旧认知</br>融入多元的青年文化</br>创造出好玩有趣的周边产品</br>帮助年轻人摆脱束缚</br>做最真实自在的自己</p>
                <img class="tag" src="@/assets/pc/img/tag_3.png" alt="">
                <img src="@/assets/pc/img/rotate_tag_3.png" alt="" class="rotate_tag rotate">
            </div>
        </div>
        <div class="security_wrap flex_align_end">
            <img :src="pcCosBaseUrl+'about_security_img.png'" alt="" class="security_img">
            <div class="security_content flex_col flex_align_start">
                <div class="title_wrap title_spot flex_justify_between">
                    <h1>安全</h1>
                    <h1>可靠</h1>
                </div>
                <p class="security_text">通过与国外优质原材料供应商、国内高端安全套生产企业协作的形式打造出专属于国人的时尚两性生活品牌。严格遵循生产质量以及管理体系，保证用户的愉悦体验。</p>
            </div>
            <img class="tag" src="@/assets/pc/img/tag_4.png" alt="">
            <span class="text_tag">Safe and reliable</span>
        </div>
        <div class="future_wrap flex_align_center">
            <img class="future_img" :src="pcCosBaseUrl+'about_future_img.png'" alt="">
            <div class="future_content flex_col flex_align_center">
                <div class="title_wrap flex_justify_between">
                    <h1 class="en">FUTURE</h1>
                    <h1>未来</h1>
                </div>
                <img class="tag" src="@/assets/pc/img/tag_4.png" alt="">
                <p class="future_text">如何满足年轻人更开放、更多样、更</br>高品质的生活需求</br>是GETO解套坚持不懈的发展方向</br>相信NEXT TIME WILL BE SPECIAL</p>
            </div>
        </div>
        <custom-foot></custom-foot>
    </div>
</template>

<script>
    import { pcCosBaseUrl } from '@/config/evn.js'
    export default {
        name: 'about',
        data() {
            return {
                pcCosBaseUrl
            }
        },
    }
</script>

<style lang="scss" scoped>
    @import '@/style/mixin.scss';


    @-webkit-keyframes rotate {
        0% {
            transform: rotate();
        }

        100% {
            transform: rotate(360deg);
        }
    }
    .rotate {
        animation: rotate 3s infinite linear;
    }

    .about_page {
        background-color: #F5F5F5;

        .title_spot {
            &::before {
                content: '';
                position: absolute;
                left: 50%;
                top: 50%;
                width: formatPX_1920(10px);
                height: formatPX_1920(10px);
                background-color: #fff;
                transform: translate3d(-50%, -50%, 0);
            }
        }

        .banner,
        .big_img {
            height: formatPX_1920(1080px);
            
        }
        .big_img {
            background-size: 100% 100%;
        }
        

        .intro_wrap {
            height: formatPX_1920(1080px);
            padding-top: formatPX_1920(160px);

            h1 {
                font-family: SourceHanSansCN-Bold;
                font-size: formatPX_1920(48px);
            }

            .intro_img_wrap {
                padding: formatPX_1920(180px) 0 0 formatPX_1920(360px);

                .intro_img {
                    width: formatPX_1920(616px);
                    height: formatPX_1920(448px);
                    margin-right: formatPX_1920(100px);
                }

                .intro_content_wrap {
                    position: relative;
                    width: formatPX_1920(430px);
                    height: formatPX_1920(448px);

                    p {
                        line-height: formatPX_1920(48px);
                        font-family: SourceHanSansCN-Regular;
                        font-size: formatPX_1920(20px);
                        color: #181818;
                        text-align: left;
                        letter-spacing: formatPX_1920(1px);
                        transform: translateY(formatPX_1920(-14px));
                    }

                    .tag_2 {
                        position: absolute;
                        right: 0;
                        bottom: formatPX_1920(9px);
                        width: formatPX_1920(168px);
                        height: formatPX_1920(34px);
                    }

                    .about_tag {
                        position: absolute;
                        right: formatPX_1920(287px);
                        bottom: 0;
                        width: formatPX_1920(153px);
                        height: formatPX_1920(53px);
                    }



                }

            }
        }

        .pack_wrap {
            position: relative;
            height: formatPX_1920(1180px);
            color: #fff;
            background-color: #181818;
            padding-top: formatPX_1920(29px);

            h2 {
                line-height: formatPX_1920(60px);
                font-family: SourceHanSansCN-Medium;
                font-size: formatPX_1920(42px);
                text-align: left;
                margin-left: formatPX_1920(400px)
            }

            .pack_img_wrap {
                height: formatPX_1920(685px);
                padding-left: formatPX_1920(360px);
                margin-top: formatPX_1920(32px);

                .pack_img {
                    width: formatPX_1920(511px);
                    height: 100%;
                    margin-right: formatPX_1920(104px);
                }

                .pack_content {
                    width: formatPX_1920(480px);
                    height: 100%;
                    padding-top: formatPX_1920(102px);

                    h3 {
                        font-family: HurmeGeometricSans3-Bold;
                        font-size: formatPX_1920(36px);
                    }

                    p {
                        line-height: formatPX_1920(48px);
                        font-family: SourceHanSansCN-Regular;
                        font-size: formatPX_1920(20px);
                        color: #EBEBEB;
                        margin-top: formatPX_1920(54px);
                    }
                }
            }

            .pack_img_mini_1 {
                position: absolute;
                left: formatPX_1920(71px);
                top: 0;
                width: formatPX_1920(289px);
                height: formatPX_1920(180px);
            }

            .pack_img_mini_2 {
                position: absolute;
                right: 0;
                top: formatPX_1920(469px);
                width: formatPX_1920(231px);
                height: formatPX_1920(144px);
                transform: translateX(formatPX_1920(20px));
                background-color: pink;
            }

            .pack_img_mini_3 {
                position: absolute;
                right: formatPX_1920(361px);
                bottom: formatPX_1920(197px);
                width: formatPX_1920(231px);
                height: formatPX_1920(144px);
            }

            .text_tag {
                position: absolute;
                right: formatPX_1920(361px);
                bottom: formatPX_1920(120px);
                width: formatPX_1920(231px);
                height: formatPX_1920(77px);
            }
        }

        .originality_wrap {
            padding: formatPX_1920(110px) formatPX_1920(301px);

            .originality_content {
                position: relative;
                width: 100%;
                height: formatPX_1920(794px);
                background-size: 100% 100%;
                padding: formatPX_1920(50px) 0 0 formatPX_1920(148px);

                .title_wrap {
                    position: relative;
                    width: formatPX_1920(438px);
                    font-family: SourceHanSansCN-Medium;
                    font-size: formatPX_1920(48px);
                    color: #fff;
                }

                .originality_title {
                    width: formatPX_1920(438px);
                    height: formatPX_1920(46px);
                    margin: formatPX_1920(25px) 0 formatPX_1920(72px);
                }

                .originality_text {
                    width: formatPX_1920(438px);
                    line-height: formatPX_1920(58px);
                    font-family: SourceHanSansCN-Regular;
                    font-size: formatPX_1920(20px);
                    color: #EBEBEB;
                }

                .tag {
                    width: formatPX_1920(62px);
                    height: formatPX_1920(62px);
                    margin: formatPX_1920(59px) 0 0 formatPX_1920(188px);
                }
                .rotate_tag {
                    position: absolute;
                    right: formatPX_1920(504px);
                    top: formatPX_1920(33px);
                    width: formatPX_1920(138px);
                    height: formatPX_1920(138px);
                }
            }
        }

        .security_wrap,
        .future_wrap {
            .title_wrap {
                position: relative;
                font-family: SourceHanSansCN-Bold;
                font-size: formatPX_1920(48px);
                letter-spacing: formatPX_1920(4px);
            }
            .security_text, .future_text {
                font-family: SourceHanSansCN-Regular;
                line-height: formatPX_1920(60px);
            }
        }

        .security_wrap {
            position: relative;
            padding: formatPX_1920(170px) 0 formatPX_1920(170px) formatPX_1920(415px);

            .security_img {
                width: formatPX_1920(536px);
                height: formatPX_1920(714px);
                margin-right: formatPX_1920(155px);
            }

            .security_content {
                width: formatPX_1920(348px);
                padding-bottom: formatPX_1920(10px);

                .title_wrap {
                    width: formatPX_1920(241px);
                    &::before {
                        background-color: #2C2C2C;
                    }
                }

                .security_text {
                    font-size: formatPX_1920(20px);
                    text-align: left;
                    letter-spacing: formatPX_1920(1px);
                    margin-top: formatPX_1920(50px);
                }
            }

            .tag {
                position: absolute;
                right: formatPX_1920(643px);
                top: formatPX_1920(205px);
                width: formatPX_1920(25px);
                height: formatPX_1920(25px);
            }

            .text_tag {
                position: absolute;
                right: formatPX_1920(416px);
                top: formatPX_1920(205px);
                font-family: HurmeGeometricSans3-Bold;
                font-size: formatPX_1920(26px);
                color: #999;
            }
        }

        .future_wrap {
            height: formatPX_1920(1063px);
            padding: formatPX_1920(250px) 0 formatPX_1920(350px) formatPX_1920(360px);

            .future_img {
                width: formatPX_1920(726px);
                height: 100%;
                margin-right: formatPX_1920(38px)
            }

            .future_content {
                width: formatPX_1920(400px);
                height: 100%;
                padding-top: formatPX_1920(12px);

                .title_wrap {
                    width: formatPX_1920(350px);
                    line-height: formatPX_1920(58px);
                    .en {
                        letter-spacing: 0;
                    }
                }
                .tag {
                    width: formatPX_1920(60px);
                    height: formatPX_1920(60px);
                    margin: formatPX_1920(43px) 0;
                }
                .future_text {
                    font-size: formatPX_1920(24px);
                }
            }
        }
    }
</style>